.main-header {
	width: 100%;
	z-index: 99;
	position: fixed;
	top: 0;
	transition: all 0.4s ease;
}

.header-desktop {
	height: 195px;
	background-image: url(../images/header-back.png);
	background-position: bottom center;
	background-size: cover;
	transition: all 0.2s ease;
	@include respond-to('medium') {
		display: none;
	}
}

.logo {
	float: $left;
	margin-top: 20px;
	margin-#{$left}: 0;
	transition: margin 0.2s ease;
	& img {
		max-width: 363px;
		transition: max-width 0.2s ease;
		@include respond-to('larger') {
			max-width: 300px;
		}
		@include respond-to('large') {
			max-width: 280px;
		}
	}
}

.main-navi {
	display: inline-block;
	margin-#{$left}: 80px;
	margin-top: 23px;
	@include respond-to('large') {
		margin-#{$left}: 40px;
	}
	@include respond-to('1100') {
		margin-#{$left}: 20px;
	}
	& > ul {
		display: inline-block;
		vertical-align: middle;
		list-style: none;
		margin: 0;
		padding: 0;
		transition: margin 0.2s ease;
		& > li {
			position: relative;
			float: $left;
			margin-#{$right}: 27px;
			@include respond-to('large') {
				margin-#{$right}: 20px;
			}
			@include respond-to('1100') {
				margin-#{$right}: 10px;
			}
			&:hover {
				.drop-menu {
				  visibility: visible;
				  opacity: 1;
				}
			  }
			&::after {
				content: '';
				position: absolute;
				#{$right}: -14px;
				top: 20%;
				height: 60%;
				width: 2px;
				background-color: $accent-b;
				@include respond-to('large') {
					#{$right}: -11px;
				}
				@include respond-to('1100') {
					#{$right}: -6px;
				}
			}
			& > a {
				display: block;
				padding: 9px 11px;
				color: $accent;
				background: transparent;
				font-size: 20px;
				font-weight: 600;
				line-height: 0.8;
				transition: color 0.2s ease;
				@include respond-to('larger') {}
				@include respond-to('1100') {
					font-size: 18px;
				}
				&:hover {
					text-decoration: none;
					background: $accent-b;
					color: #fff;
				}
				&:focus,
				&:active {
					text-decoration: none;
					background: $accent-b;
					color: #fff;
				}
				&.active {
					background: $accent-b;
					color: #fff;
				}
			}
		}
	}
	&__cont {
		display: inline-block;
		vertical-align: middle;
		padding-bottom: 3px;
		& a {
			margin-#{$left}: 5px;
		}
	}
}

.shop-btn {
	display: block;
	float: $right;
	margin-top: 17px;
	padding: 10px 22px;
	background-color: $accent;
	font-size: 18px;
	font-weight: 900;
	@include link_no-hover(#fff);
	& img {
		margin-#{$left}: 7px;
	}
}

.header-phone {
	display: inline-block;
	vertical-align: top;
	margin-top: 35px;
	margin-#{$right}: 25px;
	transition: margin 0.2s ease;
	@include respond-to('1100') {
		margin-top: 20px;
	}
	& a {
		@include link_no-hover($accent);
		font-size: 25px;
		font-weight: 700;
		& i {
			margin-#{$left}: 5px;
			color: $accent-b;
			font-size: 20px;
		}
	}
}

.header-mobile {
	display: none;
	height: 80px;
	box-shadow: 0 0 20px rgba(0, 0, 0, 0.08);
	background-color: rgba($color: #fff, $alpha: 0.9);
	@include respond-to('medium') {
		display: block;
	}
	&__logo {
		margin-top: 25px;
		margin-#{$right}: 15px;
		float: $right;
		& > img {
			max-width: 200px;
			@include respond-to('mobile-m') {
				max-width: 128px;
			}
		}
	}
	&__tel {
		display: inline-block;
		margin-top: 24px;
		padding: 0 5px;
		i {
			color: $accent;
			font-size: 30px;
		}
	}
	& .mobile-menu-btn {
		margin-top: 25px;
	}
}

.main-header.scrolling {
	box-shadow: 0 0 20px rgba(0, 0, 0, 0.08);
	background-color: rgba($color: #fff, $alpha: 0.9);
	.header-desktop {
		height: 80px;
	}
	.main-navi {
		& > ul {}
	}
	.logo {
		& img {}
	}
}

 
.drop-menu {
	visibility: hidden;
	opacity: 0;
	transition: opacity .5s ease;
	position: absolute;
	top: 33px;
	left: 50%;
	transform: translateX(-50%);
	min-width: 205px;
	margin: 0 auto;
	padding: 0 0 20px;
	background-color: #ffffff;
	box-shadow: 0px 2px 10px 1px rgba(0, 0, 0, 0.2);
	& li {
	  display: block;
	  list-style: none;
	  width: 100%;
	  padding: 0 20px;
	  text-align: center;
	  &:hover {
		background-color: #f6f6f6;
	  }
	  & a {
		display: block;
		position: relative;
		color:#452411;
		font-size: 18px;
		font-weight: 600;
		padding: 16px 0px;
		border-bottom: 1px solid #e4e4e4;
		@include respond-to('large') {
		  font-weight: 500;
		}
		&:hover,
		&:focus,
		&:active {
		  color: #452411;
		  text-decoration: none;
		}
	  }
  
	}     
  }