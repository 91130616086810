.product-box {
	display: block;
	position: relative;
	height: 518px;
	max-width: 360px;
	margin-right: auto;
	margin-left: auto;
	background-color: #fff;
	background: linear-gradient(to bottom, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 58%, rgba(255, 255, 255, 0.05) 100%);
	transition: box-shadow .2s ease;
	z-index: 1;
	@include respond-to('small') {
		margin-bottom: 20px;
	}
	@include respond-to('mobile-l') {
		height: 440px;
	}
	&::after {
		position: absolute;
		content: "";
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background: linear-gradient(to bottom, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 58%, rgba(255, 255, 255, 1) 100%);
		z-index: -1;
		transition: opacity 0.5s linear;
		opacity: 0;
	}
	&:hover,
	&:focus,
	&:active {
		box-shadow: 0px 0px 10px 3px rgba(0, 0, 0, 0.1);
		border: none;
		text-decoration: none;
		&::after {
			opacity: 1;
		}
	}
	&__img {
		position: relative;
		height: 220px;
		overflow: hidden;
		@include respond-to('mobile-l') {
			height: 190px;
		}
		& img {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			max-height: 220px;
			@include respond-to('mobile-l') {
				max-height: 190px;
			}
		}
	}
}

.product-box-info {
	padding: 40px 30px 80px;
	text-align: center;
	@include respond-to('large') {
		padding: 40px 30px 80px;
	}
	@include respond-to('large') {
		padding: 30px 15px 80px;
	}
	@include respond-to('mobile-l') {
		padding: 15px 15px 40px;
	}
	&__title {
		max-height: 86px;
		overflow: hidden;
		padding-bottom: 17px;
		border-bottom: 1px solid $accent;
		font-size: 23px;
		font-weight: 400;
		color: $accent;
		line-height: 1;
	}
	&__text {
		overflow: hidden;
		max-height: 89px;
		margin: 0 auto;
		padding: 9px 0 0;
		color: $accent;
		font-size: 18px;
		font-weight: 400;
	}
	&__button {
		position: absolute;
		bottom: 40px;
		left: 0;
		right: 0;
		margin: 0 auto;
		padding: 7px 15px;
		max-width: 230px;
		width: 100%;
		height: 40px;
		background-color: $accent-b;
		color: #fff;
		font-size: 18px;
		font-weight: 900;
		@include respond-to('mobile-l') {
			bottom: 20px;
		}
	}
}

.btn-with-cart {
	background-color: transparent;
	width: 107px;
	height: 40px;
	&__text {
		float: left;
		width: 66.6%;
		height: 100%;
		padding: 8px;
		border: 1px solid $accent;
		border-top-left-radius: 10px;
		border-bottom-left-radius: 10px;
		font-size: 16px;
		font-weight: 700;
		color: $accent;
		transition: color .2s ease, background .2s ease;
	}
	&__cart {
		float: right;
		width: 33.3%;
		height: 100%;
		border: 1px solid $accent;
		border-left: none;
		border-top-right-radius: 10px;
		border-bottom-right-radius: 10px;
		background-position: center;
		background-repeat: no-repeat;
	}
}