.banner-top-content {
	position: relative;
	width: 100%;
	min-height: 560px;
	@include respond-to('small') {
		min-height: inherit;
	}
	&__image {
		position: relative;
		background-position: center center;
		background-size: cover;
		height: 560px;
		&::after {
			content: '';
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			background-color: rgba($color: #000000, $alpha: 0.2);
		}
	}
}
