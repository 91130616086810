.proj-gallery {
	@include respond-to('large') {
		// margin-top: 30px;
		max-width: 640px;
		margin: 0 auto 30px;
	}
	// @include respond-to('medium') {
	// 	max-width: 715px;
	// 	margin: 30px auto;
	// 	padding-#{$left}: 20px;
	// }
	// @include respond-to('small') {
	// 	padding-#{$left}: 0;
	// 	margin: 30px auto 0px;
	// }
	// @include respond-to('extra-small') {
	// 	margin: 30px auto 15px;
	// }
}

.prod-item-desc {
	max-width: 535px;
	padding-#{$right}: 15px;
	@include respond-to('large') {
		max-width: 100%;
	}
	& .content-text {
		& p {
			color: $accent;
			line-height: 1.4;
		}
	}
}

.prod-item-btn {
	display: inline-block;
	width: 260px;
	height: 70px;
	margin-top: 60px;
	padding: 20px 0;
	background-color: $accent-b;
	color: #fff;
	font-weight: 900;
	font-size: 30px;
	text-align: center;
	line-height: 1;
	@include link_no-hover(#fff);
	&:active {
		@include btn-effect-active;
	}
	@include respond-to('small') {
		margin-top: 30px;
	}
}

.prod-gallery {
	position: relative;
	float: $left;
	width: 78%;
	max-width: 470px;
	padding-#{$right}: 5px;
	@include respond-to('small') {
		float: none;
		width: 100%;
		max-width: 470px;
		margin: 0 auto;
		padding-#{$right}: 0;
	}
	.slide {
		display: none;
	}
	.slide.slick-slide:first-child {
		display: block;
	}
	.slide.slick-slide {
		display: block;
	}
	&_padding {
		padding: 0;
		cursor: pointer;
	}
	&__img-big {
		position: relative;
		overflow: hidden;
		width: 100%; 
		height: 450px;
		background: #fff; 
		@include respond-to('extra-small') {
			height: 400px;
		}
		@include respond-to('mobile-l') {
			height: 250px;
		
		}
		img {
			position: absolute;
			top: 50%;
			left: 0;
			right: 0;
			transform: translateY(-50%);
			width: auto;
    		height: 100%;
			margin: 0 auto;
			@include respond-to('mobile-l') {
				position: static;
				transform: none;
			}
		}
	}
}

.prod-gallery-navi {
	position: relative;
	float: $right;
	width: 22%;
	max-width: 170px;
	height: 450px;
	text-align: center;
	margin-top: -1px;
	@include respond-to('large') {
		float: $left;
	}
	@include respond-to('medium') {
		max-width: 165px;
	}
	@include respond-to('small') {
		float: none;
		width: 100%;
		max-width: 470px;
		height: auto;
		margin: 0 auto;
		padding-#{$right}: 0;
	}
	&.slick-vertical .slick-slide {
		border: none !important;
	}
	.slick-list {
		padding: 0px 0px;
		max-height: 535px !important;
		height: 100% !important;
		@include respond-to('small') {
			padding: 15px 0;
		}
		@include respond-to('extra-small') {
			padding: 10px 0;
		}
	}
	.slide.slick-slide:first-child {
		display: block;
	}
	.slide.slick-slide {
		display: block;
	}
	&__item {
		position: relative;
		max-width: 138px;
		height: 105px;
		background: #fff;
		overflow: hidden;
		margin-bottom: 10px;
		@include respond-to('small') {
			margin: 0px;
			padding: 2px;
			height: auto;
			min-height: 80px;
		}
		@include respond-to('mobile-m') {
			min-height: 70px;
		}
		& img {
			max-height: 105px;
			height: 100%;
			width: auto;
			margin: 0 auto;
		}
	}
	&__item.slick-current {}
	& .slick-arrow {
		@include respond-to('small') {
			display: none;
		}
	}
	& .slick-prev {
		top: -20px;
		#{$right}: 69px;
		transform: translate(0) rotate(-90deg);
		@include respond-to('small') {
			#{$right}: -15px;
			top: 50%;
			transform: translateY(-50%) rotate(0);
			height: 27px;
		}
		&::before {
			content: '\276E';
			color: $accent;
			font-size: 27px;
			font-weight: 300;
		}
	}
	& .slick-next {
		bottom: -20px;
		top: auto;
		#{$right}: 69px;
		transform: translate(0) rotate(-90deg);
		@include respond-to('small') {
			#{$left}: -15px;
			#{$right}: auto;
			top: 50%;
			transform: translateY(-50%) rotate(0);
			height: 27px;
		}
		&::before {
			content: '\276F';
			color: $accent;
			font-size: 27px;
		}
	}
}

.slick-current {
	.gallery-img-small-shadow {
		background-color: rgba($color: #ffffff, $alpha: 0);
	}
}

.lg-outer {
	direction: ltr;
}

.lg-thumb {
	margin: 0 auto;
}

.decor {
	position: absolute;
	left: -50px;
	top: -350px;
	@include respond-to('huge') {
		max-width: 200px;
		top: -150px;
	}
	@include respond-to('small') {
		display: none;
	}
}

.more-products {
	position: relative;
	margin-top: 105px;
	@include respond-to('medium') {
		margin-top: 60px;
	}
	@include respond-to('small') {
		margin-top: 35px;
	}
	&__title {
		position: relative;
		z-index: 1;
		padding: 0 0 17px;
		border-bottom: 1px solid #b8a88d;
		color: $accent;
		font-size: 30px;
		font-weight: 900;
	}
	&__prods {}
}