footer {
	width: 100%;
	position: relative;
	z-index: 1;
	padding: 35px 0px 20px;
	@include respond-to('large') {
		padding: 20px 0;
	}
}

.ftr-wrap {
	position: relative;
}

.ftr-logo {
	text-align: center;
	& img {}
}

.credit-info-txt {
	position: absolute;
	right: 0;
	bottom: 0;
	display: inline-block;
	@include respond-to('small') {
		position: static;
		display: block;
		text-align: center;
		margin: 5px auto;
	}
	& a {
		font-size: 18px;
		font-weight: 900;
		@include link_no-hover(#fff);
	}
}

.site-footer__credit-holder {
	// float: $left;
	position: absolute;
	left: 0;
	bottom: 0;
	@include respond-to('small') {
		position: static;
		text-align: center;
		margin: 0 auto;
	}
}
.credit-info-txt a{
	text-shadow: 0 0 1px #000;
}