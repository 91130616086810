.horizontal-form {
	position: relative;
	z-index: 1;
	padding: 25px 0 20px;
	background-color: rgba($color: #fff, $alpha: 0.5);
	// background-image: linear-gradient(to top, #1c164d 0%, #1d3d71 100%);
	@include respond-to('large') {
		padding: 15px 0;
	}
}

.section-limit {
	&_form {
		position: relative;
		z-index: 2;
		max-width: 1430px;
		width: 100%;
	}
}

.form-horizontal {
	max-width: 1060px;
	width: 100%;
	margin: 0 auto;
	& .contact-us-form {
		padding: 0 10px;
		overflow: hidden;
	}
	& .contact-us-form__title {
		margin-bottom: 25px; 
		text-align: center;
		font-size: 25px;
		font-weight: 400;
		color: $accent; 
		line-height: 1;
		@include respond-to('small') {
			font-size: 22px;
		}
		& span {
			display: block;
			// white-space: nowrap;
			font-size: 25px;
			font-weight: 400;
			@include respond-to('small') {
				font-size: 22px;
			}
		}
	}
	& .contact-wrap-input {
		@include respond-to('larger') {
			font-size: 0;
			text-align: center;
		}
	}
	& .form-group {
		float: $left;
		vertical-align: top;
		margin: 0 22px 0 0;
		max-width: 254px;
		width: 100%;
		@include respond-to('larger') {
			max-width: 260px;
			float: none;
			display: inline-block;
			margin: 0;
			padding: 0 8px;
		}
		@include respond-to('1100') {
			// max-width: 23%;
		}
		@include respond-to('medium') {
			// max-width: 50%;
		}
		@include respond-to('extra-small') {
			padding: 0 4px;
			// max-width: 100%;
		}
		// &_check {
		// 	max-width: 108px;
		// 	@include respond-to('larger') {
		// 		max-width: 40%;
		// 		margin: 0;
		// 	}
		// 	@include respond-to('extra-small') {
		// 		margin-top: 2px;
		// 		max-width: 44%;
		// 	}
		// }
		&_ml {
			margin-#{$left}: 0;
		}
		&_ta {
			max-width: 260px;
			@include respond-to('large') {
				margin-bottom: 10px;
			}
		}
		// & textarea {
		// 	height: 45px;
		// 	padding: 11px 10px 0;
		// 	max-width: 260px;
		// 	@include respond-to('medium') {
		// 		max-width: 100%;
		// 	}
		// 	@include respond-to('extra-small') {
		// 		height: 50px;
		// 		padding: 12px 10px 0;
		// 	}
		// }
		&_button {
			max-width: 165px;
			float: $right;
			margin: 0;
			// margin-#{$left}: 30px;
			@include respond-to('huge') {
				// max-width: 160px;
			}
			@include respond-to('larger') {
				display: block;
				margin: 0 auto;
				max-width: 260px;
				width: 100%;
				float: none;
			}
			@include respond-to('extra-small') {
				// margin-top: 10px;
				// max-width: 44%;
				// min-width: 260px;
			}
		}
	}
	& .newslatter-input {
		height: 50px;
		width: 100%;
		padding: 11px 10px 10px;
		// height: 100%;
		background-color: #fff;
		color: #838383;
		font-weight: 300;
		// box-shadow: inset 0 2px 8px rgba(0, 0, 0, 0.1);
		font-size: 20px;
		border: none;
		border-radius: 0;
		@include respond-to('extra-small') {
			padding: 14px 10px 13px;
		}
		&::placeholder {
			font-size: 20px;
			color: #bdbdbd;
		}
	}
	& .newslatter-btn {
		width: 100%;
		margin: 0;
		height: 50px;
		font-size: 18px;
		font-weight: 900;
		background-color: $accent-b;
		border: none;
		color: #fff;
		&:active {
			@include btn-effect-active;
		}
	}
}

.help-block {
	font-size: 16px;
	color: $accent !important;
}

.form-control:focus {
	border-radius: 0;
}

// .ftr-check-uncheck {
// 	position: relative;
// 	display: inline-block;
// 	width: 23px;
// 	height: 23px;
// 	margin-#{$left}: 5px;
// 	margin-top: 5px;
// 	vertical-align: top;
// 	label {
// 		width: 23px;
// 		height: 23px;
// 		cursor: pointer;
// 		position: absolute;
// 		background-color: #eeeeee;
// 		border: 1px solid #bebebe;
// 		#{$left}: 0;
// 		top: 0;
// 		&::after {
// 			content: url(../images/check.png);
// 			position: absolute;
// 			top: -3px;
// 			#{$left}: 3px;
// 			opacity: 0;
// 			@include respond-to('larger') {
// 				top: 3px;
// 			}
// 		}
// 	}
// 	input[type=checkbox] {
// 		visibility: hidden;
// 		&:checked + label::after {
// 			opacity: 1;
// 		}
// 	}
// 	&_side {
// 		margin-top: 20px;
// 		label::after {
// 			top: -2px;
// 		}
// 	}
// }

// .ftr-checkbox-para {
// 	display: inline-block;
// 	max-width: 75px;
// 	@include respond-to('larger') {
// 		max-width: 150px;
// 	}
// 	& > p {
// 		font-size: 14px;
// 		font-weight: 300;
// 		color: #000;
// 		margin: 0;
// 		@include respond-to('extra-small') {
// 			font-size: 13px;
// 		}
// 	}
// 	&_side {
// 		margin-top: 15px;
// 		max-width: 128px;
// 	}
// }