.wrapper-all-content {
	background-position: top center;
	position: relative;
	width: 100%;
	&_pages {
		// top: -150px;
		// margin-bottom: -150px;
		// padding-top: 100px;
		// background-size: cover;
		@include respond-to('small') {
			// background-size: unset;
			padding-top: 1px;
		}
		&::after {
			content: '';
			position: absolute;
			left: 0;
			right: 0;
			top: -157px;
			width: 100%;
			height: 157px;
			background-image: url(../images/page-top.png);
			background-position: top center;
			background-repeat: no-repeat;
			background-size: cover;
			@include respond-to('small') {
				display: none;
			}
		}
		& .section-limit_cont {
			position: relative;
			z-index: 1;
			top: -50px;
			margin-bottom: -50px;
			@include respond-to('small') {
				top: 0px;
				margin-bottom: 0px;
			}
		}
	}
}

.title {
	margin: 0;
	font-size: 40px;
	font-weight: 900;
	line-height: 1;
	color: $accent;
	@include respond-to('small') {
		font-size: 35px;
	}
	&_with-label {
		position: relative;
		@include respond-to('small') {
			text-align: center;
		}
		&::before {
			display: block;
			margin-top: 5px;
			content: url(../images/title-logo.png);
		}
	}
	&_tac {
		text-align: center;
	}
	&_mb {
		margin-bottom: 55px;
		@include respond-to('small') {
			margin-bottom: 35px;
		}
		@include respond-to('extra-small') {
			margin-bottom: 25px;
		}
	}
	&_prod-i {
		@include respond-to('large') {
			text-align: center;
		}
	}
}

.hp-about {
	position: relative;
	top: -120px;
	margin-bottom: -120px;
	padding: 172px 0;
	background-position: top center;
	background-repeat: no-repeat;
	@include respond-to('huge') {
		padding: 120px 0 170px;
		background-position: top #{$right} -200px;
	}
	@include respond-to('large') {
		background-position: top #{$left} -100px;
	}
	@include respond-to('medium') {
		background-position: top #{$left} -140px;
	}
	@include respond-to('small') {
		background-image: none !important;
		padding: 30px 0 70px;
		top: 0;
		margin-bottom: 0;
	}
	@include respond-to('extra-small') {
		padding: 20px 0 50px;
	}
	&__wrap {
		max-width: 830px;
		width: 100%;
		@include respond-to('large') {
			margin: 0 auto;
		}
	}
	&__title {
		display: inline-block;
		@include respond-to('small') {
			display: block;
			margin-top: 25px;
		}
	}
	&__icons {
		float: $right;
		@include respond-to('small') {
			display: block;
			float: none;
			margin-top: 20px;
		}
	}
	&__desc {
		margin-top: 50px;
		@include respond-to('small') {
			margin-top: 30px;
		}
		@include respond-to('extra-small') {
			margin-top: 20px;
		}
		& p {
			color: $accent;
			font-size: 18px;
			font-weight: 600;
			@include respond-to('extra-small') {
				font-size: 16px;
				text-align: justify;
			}
		}
	}
	&__prods {
		padding-top: 50px;
		@include respond-to('small') {
			padding-top: 20px;
		}
	}
}

.cream {
	pointer-events: none;
	position: absolute;
	left: 0;
	top: -50%;
	@include respond-to('1750') {
		left: -100px;
	}
	@include respond-to('1550') {
		max-width: 250px;
		top: -20%;
		left: -50px;
	}
	@include respond-to('larger') {
		top: -15%;
	}
	@include respond-to('small') {
		display: none;
	}
}

.hp-products {
	position: relative;
	padding-bottom: 120px;
	@include respond-to('large') {
		padding-bottom: 90px;
	}
	@include respond-to('small') {
		padding-bottom: 50px;
	}
	&__title {}
	&__boxes {}
}

.products {
	font-size: 0;
	margin: 75px -30px 0;
	text-align: center;
	@include respond-to('larger') {
		margin: 45px -20px 0;
	}
	@include respond-to('1100') {
		margin: 45px -10px 0;
	}
	&_mt45 {
		margin-top: 45px;
		@include respond-to('small') {
			margin-top: 25px;
		}
	}
	&_mt25 {
		margin-top: 25px;
	}
	&__item {
		display: inline-block;
		width: 33.33%;
		vertical-align: top;
		font-size: inherit;
		padding: 30px;
		@include respond-to('larger') {
			padding: 20px;
		}
		@include respond-to('1100') {
			padding: 10px;
		}
		@include respond-to('medium') {
			width: 50%;
		}
		@include respond-to('small') {
			width: 100%;
		}
	}
	&__item2 { 
		display: inline-block;
		width: 34%;
		vertical-align: top;
		font-size: inherit;
		padding: 30px;
		@include respond-to('larger') {
			padding: 20px;
		}
		@include respond-to('1100') {
			padding: 10px;
		}
		@include respond-to('medium') {
			width: 50%;
		}
		@include respond-to('small') {
			width: 100%;
		}
	}
}