.nuts {
	font-size: 0;
	text-align: center;
	&__item {
		display: inline-block;
		vertical-align: top;
		width: 20%;
		font-size: inherit;
		@include link_no-hover($accent);
		@include respond-to('small') {
			width: 33.33%;
			margin: 10px 0;
		}
		@include respond-to('extra-small') {
			width: 50%;
		}
	}
}

.nut {
	text-align: center;
	&__img {
		height: 90px;
		& img {
			display: inline-block;
			max-height: 90px;
			vertical-align: middle;
		}
		&::after {
			content: '';
			display: inline-block;
			vertical-align: middle;
			height: 100%;
		}
	}
	&__title {
		padding: 0 2px;
		margin-top: 20px;
		color: $accent;
		font-size: 20px;
		font-weight: 900;
		line-height: 1;
		// text-transform: uppercase;
	}
}